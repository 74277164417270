import Table from './table';
import Statistics from './statistics';
import Subtitles from './subtitle';

export default function Main() {
  return (
    <>
      <div className='mx-3'>
        {/* <Table /> */}
        {/* <Statistics /> */}
        {/* <Subtitles /> */}
      </div>
    </>
  );
}
