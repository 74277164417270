import { useState, useEffect } from "react";
import { getPropertyById, updateProperty } from "../../api/request";
import { showError, showLoading, showSuccess, showWarning } from "../../components/messages";

export default function ManageProperty({ edit, id, setSwitchPanel }) {
  const [propertyData, setPropertyData] = useState('');
  const [values, setState] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);

  const init = () => {
    setSuccess(false);
    setError(false);
    setWarning(false);
    setLoading(true)
    getPropertyById(id)
      .then(data => {
        if (data !== undefined) {
          setLoading(false);
          if (data.error) {
            return setError('Something went wrong!')
          } else {
            const { success, error, message, result } = data;
            if (error) {
              return setError(message);
            }
            if (success) {
              setState(result);
              setPropertyData(result);
            }
          }
        }
      });
  }

  useEffect(() => {
    init();
  }, []);

  const getChangedValues = () => {
    const changedValues = {};
    for (const key in values) {
      if (propertyData.hasOwnProperty(key) && propertyData[key] !== values[key]) {
        changedValues[key] = values[key];
      }
    }
    changedValues.createdAt && delete changedValues.createdAt
    changedValues.updatedAt && delete changedValues.updatedAt
    return { ...changedValues, id: values.id };
  };

  const onSave = () => {
    if (edit === 'false') {
      return;
    }
    setSuccess(false);
    setError(false);
    setWarning(false);
    setLoading(true)
    const changedValues = getChangedValues();
    const isEmpty = Object.keys(changedValues).length === 1;

    if (isEmpty) {
      setTimeout(() => {
        setLoading(false);
      }, 200);
      return setWarning('No Change');
    }

    updateProperty(changedValues)
      .then(data => {
        setLoading(false);
        if (data.error) {
          return setError(data.message)
        } else {
          const { error, success, message } = data;
          if (error) {
            return setError(message);
          }
          if (success) {
            return setSuccess(message);
          }
        }
      });
    setPropertyData(values);
  }

  const handleChange = (event) => {
    setSuccess(false);
    setError(false);
    setLoading(false);
    setWarning(false);
    event.preventDefault();
    const { name, value } = event.target;
    setState({
      ...values,
      [name]: value,
    });
  }

  const notEditable = () => { }

  const LabeledInput = props => (
    <>
      {edit === 'true' && (
        <div className='col-xxl-4 col-lg-6 col-sm-12  mb-4'>
          <div className='form-control pb-4'>
            <label htmlFor={props.label} className='form-label'>
              {props.label}
            </label>
            <input
              type={props.type}
              value={props.value}
              name={props.name}
              className='form-control'
              onChange={edit === 'true' ? handleChange : notEditable}
            />
          </div>
        </div>
      )}
      {edit === 'false' && (
        <div className='col-xxl-4 col-lg-6 col-sm-12  mb-4'>
          <div className='form-control pb-4'>
            <label htmlFor={props.label} className='form-label'>
              {props.label}
            </label>
            <div className='form-control'>{props.value}</div>
          </div>
        </div>
      )}
    </>
  );

  const LabeledTextArea = props => (
    <>
      {edit === 'true' && (
        <div className='w-100 mb-4'>
          <div className='form-control pb-4'>
            <label htmlFor={props.label} className='form-label'>
              {props.label}
            </label>
            <textarea
              type={props.type}
              value={props.value}
              name={props.name}
              className='form-control'
              onChange={edit === 'true' ? handleChange : notEditable}
              style={{ height: '200px' }}
            />
          </div>
        </div>
      )}
      {edit === 'false' && (
        <div className='w-100 mb-4'>
          <div className='form-control pb-4'>
            <label htmlFor={props.label} className='form-label'>
              {props.label}
            </label>
            <div className='form-control' style={{ height: '200px' }}>
              {props.value}
            </div>
          </div>
        </div>
      )}
    </>
  );

  const Section = props => (
    <div className=''>
      <div>{props.name}</div>
      <div className='i-b'>
        <div className='row'>{props.labels}</div>
      </div>
    </div>
  );

  const Property = () => (
    <div className=''>
      {Section({
        name: 'Property Information',
        labels: (
          <>
            {LabeledInput({
              label: 'Name',
              type: 'text',
              value: values.name,
              name: 'name',
            })}
            {LabeledInput({
              label: 'Price',
              type: 'text',
              value: values.price,
              name: 'price',
            })}
            {LabeledInput({
              label: 'Quantity',
              type: 'text',
              value: values.quantity,
              name: 'quantity',
            })}
            {LabeledInput({
              label: 'Type',
              type: 'text',
              value: values.type,
              name: 'type',
            })}
          </>
        ),
      })}

      {Section({
        name: 'About Property',
        labels: (
          <>
            {LabeledTextArea({
              label: 'Description',
              type: 'text',
              value: values.description,
              name: 'description',
            })}
          </>
        ),
      })}

      {Section({
        name: 'Address Information',
        labels: (
          <>
            {LabeledInput({
              label: 'Country',
              type: 'text',
              value: values.country,
              name: 'country',
            })}
            {LabeledInput({
              label: 'State/Province/Region',
              type: 'text',
              value: values.state,
              name: 'state',
            })}
            {LabeledInput({
              label: 'City',
              type: 'text',
              value: values.city,
              name: 'city',
            })}
            {LabeledInput({
              label: 'Zip Code',
              type: 'text',
              value: values.zipCode,
              name: 'zipCode',
            })}
            {LabeledInput({
              label: 'Street',
              type: 'text',
              value: values.street,
              name: 'street',
            })}
            {LabeledInput({
              label: 'Map Location',
              type: 'text',
              value: values.location,
              name: 'location',
            })}
          </>
        ),
      })}

      {Section({
        name: 'Status',
        labels: (
          <>
            {LabeledInput({
              label: 'Status',
              type: 'text',
              value: values.status,
              name: 'status',
            })}
          </>
        ), 
      })}
      <div className='d-flex justify-content-between mt-5 mb-5'>
        <div>
          <button
            onClick={() => setSwitchPanel('main')}
            className='btn btn-warning'
          >
            Back
          </button>
        </div>
        <div>
          {edit === 'true' && (
            <button className='btn btn-primary' onClick={onSave}>
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='w-100'>
        <div className='w-100'>
          {showError(error)}
          {showSuccess(success)}
          {showWarning(warning)}
        </div>
        <div className='position-absolute top-50 start-50 translate-middle'>
          {showLoading(loading)}
          {loading && (
            <button
              onClick={() => setSwitchPanel('main')}
              className='btn btn-warning mt-5'
            >
              Back
            </button>
          )}
        </div>
        {loading === false && Property()}
      </div>
    </>
  );
}
